import { getDiscipline } from '../../lib/brain';
import redirect from '../../lib/redirect';

function path(disciplineName) {
  return `/en/discipline/${disciplineName.toLowerCase()}/`;
}

export default function ResultRouter() {
  const { disciplineName } = getDiscipline();
  if (disciplineName === null) {
    redirect(path('Responsibility'));
  } else {
    redirect(path(disciplineName));
  }
  return null;
}
